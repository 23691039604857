<template>
  <div>
    <div class="relatedMainLayout" v-if="dataFetched">
      <div :class="[localDisplayLang === 'ara' ? 'relatedCardsAra' : 'relatedCards']">
        <div v-if="relatedContentDetails && relatedContentDetails.length == 0" class="error-message">
          <p> {{$t("No contents found")}} </p>
        </div>

        <div v-else class="relatedCard" v-for="related in relatedContentDetails" :key="related.objectid">
          <div class="relatedContainer">
            <router-link :to="navigateToDetail(related)">
            <div class="relatedPosterLayout">
              <img :src="getPoster(related)" class="relatedPoster" :alt="related.title" :title="related.title"/>
            </div>
            <div v-if="isObjectTag(related) && pickTagForContent(related)" :class="[localDisplayLang === 'ara' ? 'relatedTagRight' : 'relatedTag']">
              <img src="@/assets/icons/Premium_1_scg.svg" alt="crown">
            </div>
            <div class="relatedContentLayout">
              <div class="relatedContentSplitter">
                <p class="relatedTitle">{{ related.title }}</p>
              </div>
              <p class="relatedDescription" v-if="related.shortdescription || related.longdescription">
  
                {{ getDescription(related) }}
              </p>
            </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!dataFetched">
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
export default {
  props: {
    contentDetails: {
      type: Object,
    },
  },
  data() {
    return {
      dataFetched: false,
      seasonNum: 1,
      seasonCount: null,
      localDisplayLang: null,
      seasonCountArray: [],
      isMpegRequired: false,
      relatedContentDetails: null,
      containerHover: "container",
      noContainerHover: "container-no-hover",
    };
  },

  computed: {
    checkSeries: function() {
      if (this.contentDetails.category == "MOVIE") {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.fetchRelatedContents(this.contentDetails.objectid);
  },
  mounted() {
    eventBus.$on("relatedcontents-response", (response) => {
      this.dataFetched = true;

      if(!response.errorcode) {
        this.relatedContentDetails = response.data;
      } else {
        this.relatedContentDetails = [];
      }
    });
  },
  methods: {

    getDescription(related) {

      if(related.shortdescription && related.shortdescription.length > 250) {
        return related.shortdescription.slice(0, 250);
      } else if (related.longdescription && related.longdescription.length > 250) {
        return related.longdescription.slice(0, 250);
      } else if(related.shortdescription) {
        return related.shortdescription;
      } else if(related.longdescription) {
        return related.longdescription;
      }

    },
    pickTagForContent(content) {
      if (content.hasOwnProperty("objecttag")) {
        let tag = content.objecttag[0];
        let contenttags = this.appConfig.contentTags;
        if (this.localDisplayLang === "mar") {
          return contenttags.mr[`${tag}`];
        } else {
          return contenttags.en[`${tag}`];
        }
      }
    },
    isObjectTag(content) {
      if (content.hasOwnProperty("objecttag")) {
        if (Array.isArray(content.objecttag)) {
          return content.objecttag.length === 0 ? false : true;
        } else {
          return content.objecttag === null ? false : true;
        }
      }
    },
    fetchRelatedContents(contentId) {
      let pageSize = 15;
      let payload = {
        contentid: contentId,
        params: {
          displaylanguage: this.localDisplayLang == "mr" ? "mar" : this.localDisplayLang,
          pagesize: pageSize,
          // page: pageNum,
        },
      };

      if (this.isMpegRequired == true) {
        payload.params.mpegtspackage = "YES";
      }

      eventBus.$emit("getRelatedcontents", payload);
    },

    getPoster(content) {
      if (content && content.poster && content.poster.length && !content.inwatchlist) {
        let index = content.poster.findIndex((element) => {
          return element.postertype === "LANDSCAPE";
        });

        if (index > -1) {
          return content.poster[index].filelist[1].filename;
        }
      } else if (content.inwatchlist) {
        return content.poster.landscape;
      }
    },
    navigateToDetail(relatedItem) {

      sessionStorage.setItem("isActiveDesktopView", true);

      let currentLanguage = this.getCurrentLanguageUrlBase();
      if(relatedItem?.tags?.toString()?.toLowerCase()?.includes('ct-music')) {
        let title = this.spacialCharEncoding(relatedItem.defaulttitle || relatedItem.title)
        return ({ name: "detailPageMusic", params: { contentId: relatedItem.objectid.toLowerCase(), mediaTitle: title.toLowerCase(), lang: currentLanguage } });
      } else if (relatedItem.category == "TVSHOW") {
        let title = this.spacialCharEncoding(relatedItem.defaulttitle)

        return ({ name: "detailPage", params: { contentId: relatedItem.objectid.toLowerCase(), mediaTitle: title.toLowerCase(), lang: currentLanguage } });
      } else {
     
        let title = this.spacialCharEncoding(relatedItem.defaulttitle)
        return ({ name: "detailPageMovie", params: { mediaTitle: title.toLowerCase(), contentId: relatedItem.objectid.toLowerCase(), lang: currentLanguage } });
      }
    },
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./relatedCard.scss"

</style>
